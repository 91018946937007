<template lang="pug">
  v-data-table.clickable(
    :headers="headers"
    :loading="areTechsPending"
    no-data-text="No Techs Found"
    :options.sync="options"
    :server-items-length="haveTechsLoaded ? paginationData.default.mostRecent.total : 0"
    :items="techs"
    @click:row="(item) => $router.push({name: 'edit-tech', params: { techId: item.id }})"
    :footer-props="{ itemsPerPageOptions: [5,10,15] }"
  )
    template(v-slot:item.shop_id="{ item: tech }") {{ tech.shop.name }}
    template(#body.prepend)
      tr
        td(colspan="100")
          v-btn(
            color="primary"
            block
            :to="{ name: 'edit-tech', params: { techId: 'new' }, query: { shopId }}"
          )
            v-icon(
              left
            ) mdi-plus
            | Add Tech

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'TechsTable',
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Shop',
            value: 'shop_id'
          },
          {
            text: 'Status',
            value: 'status'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { Tech } = context.root.$FeathersVuex.api

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }

      if (props.search !== null && props.search !== '') {
        params.name = {
          $regex: props.search,
          $options: 'gi'
        }
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (props.search !== null && props.search !== '') {
        params.name = {
          $iRegexp: props.search
        }
      }

      return { query: { ...params, $eager: 'shop' } }
    })

    // Do the actual queryObjing, be sure to remember to paginate
    const { items: techs, isPending: areTechsPending, paginationData, haveLoaded: haveTechsLoaded } = useFind({
      model: Tech,
      params,
      fetchParams,
      paginate: true
    })

    return {
      options,
      sortBy,
      limit,
      techs,
      queryObj,
      params,
      fetchParams,
      areTechsPending,
      haveTechsLoaded,
      paginationData
    }
  },
  data: () => ({

  })
}
</script>
