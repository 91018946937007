<template lang="pug">
  v-card.mb-16(
    outlined
  )
    v-card-title
      v-layout(
        flex
      )
        h3.grey--text.text--darken-2 Techs
        v-spacer
        v-text-field.flex-shrink-1.flex-grow-0(
          v-model="search"
          label="Search Techs"
          single-line
          prepend-inner-icon="mdi-magnify"
          hide-details
          filled
          rounded
          dense
        )
    TechsTable(
      :search="search"
      :query="{ shop_id: shopId }"
      :headers="techsHeaders"
      :shopId="shopId"
    )

</template>

<script>
import TechsTable from '@/components/tables/TechsTable'

export default {
  name: 'ShopTechs',
  components: {
    TechsTable
  },
  props: {
    shopId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    search: null,
    techsHeaders: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ]
  })
}
</script>
